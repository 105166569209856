import React from "react";
import ProjectView from "../../../views/project-view";
import { graphql } from 'gatsby'    

const ArchitekturProject = (props) => {
  const project = props.data.architektur
  const imgData = props.data.allFile
  const projects = props.data.allArchitektur && props.data.allArchitektur.nodes
  const location = props.location
  const wohnen = props.data.wohnen.nodes
  const bauenBestand = props.data.bauenBestand.nodes
  const arbeitFreizeit = props.data.arbeitFreizeit.nodes
  const wettbewerbe = props.data.wettbewerbe.nodes
  const categoryArray = {
    1: wohnen,
    2: bauenBestand,
    3: arbeitFreizeit,
    4: wettbewerbe
  }
    return(
      <ProjectView 
        project={project} 
        projects={projects} 
        images={imgData} 
        location={location} 
        categoryArray={categoryArray} />
    )
  }

export default ArchitekturProject


export const query = graphql`
query ($id: String, $category: String) {
  architektur(id: { eq: $id }) {
      title
      category
      slug
      _id
      city
      year
      description1
      description2
      files { 
        url
        text1
        text2
       }
       images {
         alt_1
         alt_2
         alt_3
         alt_4
         alt_5
         alt_6
         alt_7
         alt_8
         alt_9
         alt_10
         alt_11
         alt_12
         alt_13
         alt_14
         alt_15
         alt_16
         alt_17
       }
      gatsbyPath(filePath: "/architektur/{Architektur.category}/{Architektur.slug}")
    }
  allFile(filter: {sourceInstanceName: {eq: $category}, 
    extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/"},
    name: {regex: "/[a-zA-Z0-9][_][0-9]/"}}) {
    nodes {
      name
      childImageSharp {
        gatsbyImageData
      }
      relativePath
    }
  }
  allArchitektur {
    nodes{
			_id
      slug
      title
      category
    }
  }
  wohnen: allArchitektur(filter: {category: {eq: "wohnen"}}) {
    nodes {
      title
      category
      _id
    }
  }
  bauenBestand: allArchitektur(filter: {category: {eq: "bauen-im-bestand"}}) {
    nodes {
      title
      category
      _id
    }
  }
  arbeitFreizeit: allArchitektur(filter: {category: {eq: "arbeit-freizeit"}}) {
    nodes {
      title
      category
      _id
    }
  }
  wettbewerbe: allArchitektur(filter: {category: {eq: "wettbewerbe-konzepte"}}) {
    nodes {
      title
      category
      _id
    }
  }
}
`